* {
  padding: 0;
  margin: 0;
}

.base-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em;
  justify-content: space-around;
}
.base-info {
  border: 1px solid whitesmoke;
  padding: 1em;
}

* {
  padding: 0;
  margin: 0;
}
.heading-image {
  background-color: #f6f6fb;
  height: 40vh;
}
.heading-title h1 {
  padding: 2em;
  margin-left: 3em;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 35px;
  font-style: normal;
  color: #466477;
  font-weight: 300;
}
.hamburger-menu {
  display: none;
}
.base-flex,
.base-flex-2,
.base-flex-3 {
  display: flex;
  margin-top: 2em;
}
.base-design {
  padding: 1em;
}
.base-design h1 {
  padding-top: 0.3em;
}
.base-main {
  display: flex;
  justify-content: center;
  align-items: center;

  justify-content: space-around;
}
.base-icon {
  font-size: 45px;
}
.base-flex,
.base-flex-1,
.base-flex-2,
.base-flex-3 {
  border: 2px solid whitesmoke;
  padding: 20px 20px 20px;
  padding-right: 3em;
}
.base-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.base-flex h1,
.base-flex-1,
h1 .base-flex-2,
h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: #323232;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  /* margin-left: 0.5em; */
}
.base-design h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: #323232;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.base-design h5 {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #323232;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  /* margin-left: 0.5em; */
}
/*base*/
/* .base-flex img {
  height: 50px;
  object-fit: cover;
} */

/* .base-main {
  display: flex;
  justify-content: center;
  align-items: center;

  justify-content: space-around;
} */
/* .base-contain {
  border: 2px solid whitesmoke;
  padding: 20px 20px 20px;
  padding-right: 3em;
} */
/* .base-contain {
  display: flex;
  justify-content: center;
  align-items: center;
} */
 /* .base-flex h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: #323232;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-left: 0.5em;
}  */
/* .base-flex h5 {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #323232;
  font-family: Roboto, Arial, Helvetica, sans-serif;
} */

/*campaign */
.campaign-main {
  display: flex;
  justify-content: center;
  align-items: center;

  justify-content: space-around;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.campaign-info h1 {
  font-size: 18px;
  font-style: normal;
  color: #323232;
  font-weight: 600;
  line-height: 32px;
}
.campaign-head ul li {
  margin-top: 1.5em;
  font-size: 15px;
  font-style: normal;
  color: #5c5c5c;
  font-weight: 400;
}
.campaign-icons {
  padding: 0.5em;
}
.campaign-map {
  height: 2px;
  width: 120%;
  margin-top: 1em;
  color: grey;
}
.campaign-info-1 {
  margin-bottom: 15em;
}
.campaign-info-1 h1 {
  font-size: 18px;
  font-style: normal;
  color: #323232;
  font-weight: 600;
  line-height: 32px;
}
/*basic*/
.basic-main {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 2em;
  justify-content: space-around;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.basic-info h1 {
  font-size: 18px;
  font-style: normal;
  color: #323232;
  font-weight: 600;
}
.basic-info ul li {
  margin-top: 1.5em;

  font-size: 15px;
  font-style: normal;
  color: #5c5c5c;
  font-weight: 400;
}
.campaign-design {
  height: 2px;
  width: 20%;
  margin-top: 1em;
  color: grey;
}
.center-main-1 {
  margin-left: 6em;
  margin-top: 3em;
}
.campaign-head-1 ul li {
  margin-top: 1em;
  font-size: 15px;
  font-style: normal;
  color: #5c5c5c;
  font-weight: 400;
}
.campaign-info-1 h1 {
  font-size: 18px;
  font-style: normal;
  color: #323232;
  font-weight: 600;
  line-height: 32px;
}
.base-flex i {
  font-size: 50px;
  margin-left: 0.1em;
}
@media (max-width: 750px) {
  .basic-main {
    flex-direction: column;
    margin-top: 1em;
  }
  .campaign-main {
    flex-direction: column;
  }
  .campaign-info {
    margin-top: 2em;
  }
  .campaign-info h1 {
    margin-top: 1em;
  }
  .basic-info {
    margin-top: 1em;
  }
  .basic-info h1 {
    margin-top: 1em;
  }
  .base-main {
    flex-direction: column;
  }
  .base-contain {
    width: 100%;
  }
  .heading-title h1 {
    margin-left: 0px;
    font-size: 25px;
    /* margin-top: 2em; */
  }
  .heading-image {
    height: 30vh;
  }
   .base-contain {
    flex-direction: column;
  }
  .base-flex, .base-flex-1, .base-flex-2, .base-flex-3 {
    padding-right: 0em;
  }
  
}
@media (max-width: 1100px) {
  .hamburger-menu {
    display: inline-block;
    padding-top: 0.6em;

    font-size: 25px;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    /* margin-left: 4em; */
  }
  /* .navbar {
    position: relative;
  }*/
  .mobile-menu-link {
    flex-direction: column;
    position: absolute;
    top: 3em;
    right: 0px;
    background-color: white;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 11.9em;
    z-index: 9999;
  }
  .menu-nav-link {
    height: 15rem;
    width: 100%;
  }
  .mobile-menu-link .menu {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    z-index: 5;
  }
  .mobile-menu-link .menu li {
    transition-delay: 0.2s;
    font-size: 15px;
  }
  .hamburger-flex {
    display: flex;
    justify-content: space-between;
  }
  .menu-link {
    display: none;
  }
  .about-head-button {
    display: none;
  }
  .campaign-map{
    width: 100%;
  }
}

* {
  padding: 0;
  margin: 0;
}
.bar-item{
  display: none;
}
.menu-link-1{
  display: none;
}
.head-field {
  background-color: #233038;
  height: 60vh;
}
.info-head {
  background-color: #fff;
}
.logo-head {  
  padding-top: 1em;

  margin-left: 2em;
}
.logo-head img{
  height: 35px;
}
.head-area h1 {
  color: #fff;
}


.menu-head li {
  margin-left: 2.3em;
  color: black;
  cursor: pointer;
  padding-top: 0em;
}
.menu-head li :hover {
  color: green;
}
.navbar-1 {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.hamburger-menu {
  display: none;
}
.menu-head {
  display: flex;
  padding-top: 1.7em;
  margin-left: 29em;
}
.about-head-button {
  padding-right: 5em;
  padding-top: 0.9em;
}
.about-btn-head {
  padding: 0.6em;
  background-color: #2cde73;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-style: normal;
  transition: transform 0.2s;
  border: 1px solid transparent;
  width: 100px;
}
.about-btn-head:hover {
  background-color: whitesmoke;
  color: #236c7e;
  border: 1px solid #236c7e;
}
.head-area {
  color: white;
  padding: 6em;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.head-area h1 {
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
}
.head-area h4 {
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  margin-top: 0.4em;
}
.head-info {
  font-size: 35px;
  font-style: normal;
  font-weight: 300;
}
.head-map {
  width: 30%;
  height: 1px;
  margin-top: 0.3em;
}
.navbar-head {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.head-map-1 {
  width: 25%;
  margin-left: 4.6em;
  margin-top: 0.3em;
  height: 1px;
}
.head-area img {
  position: absolute;
  top: 10px;
  left: 1em;
}
.head-icon {
  font-size: 30px;
}
.head-area h5 {
  margin-top: 6em;
  font-size: 15px;
  font-style: normal;
}
.invest-field-1 {
  background-color: whitesmoke;
  padding: 2em;
}
.heading-image {
  background-color: whitesmoke;
}
/*media query */
@media (max-width: 1100px) {
  .logo-head {
    padding-top: 1em;
    margin-left: 1em;
  }
  .head-area {
    padding: 2em;
    padding-top: 3em;
  }
  .head-area h1 {
    font-size: 30px;
  }
  .head-area h4 {
    font-size: 20px;
  }
  .head-info {
    font-size: 18px;
  }
  .head-map {
    width: 85%;
    height: 1px;
    margin-top: 0.3em;
  }
  .head-map-1 {
    width: 60%;
    margin-left: 4.6em;
    margin-top: 0.3em;
    height: 1px;
  }
  .hamburger-menu {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    /* padding-right: 1em;  */
    /* margin-left: 4em; */
  }
  .hamburger-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* align-items: center; */
  }
  /* .hamburger-flex img{
    width: 4rem;
    object-fit: cover;
  } */
  .mobile-menu-link {
    flex-direction: column;
    position: absolute;
    top: 3em;
    right: 0px;
    background-color: white;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 11.9em;
    z-index: 9999;
  }
  .menu-nav-link {
    height: 15rem;
    width: 100%;
  }
  .mobile-menu-link .menu-head {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    z-index: 5;
  }
  .navbar-1 {
  height: 7vh;
  }
  .head-area h1{
    color: #fff;
  }
  .head-field{
    height: 35vh;
  }
  .mobile-menu-link .menu li {
    transition-delay: 0.2s;
    font-size: 15px;
  }
  .menu-head {
    display: none;
  }
  .about-head-button {
    display: none;
  }
  .logo-head img{
    height: 25px;
  }
  .navbar-1{
    display: none;
  }
  .bar-item{
    display: block;
  }
  .bar-image img{
      height: 40px;
      width: 100%;
      margin-top: 0.5em;
      position: relative;
  }
  .logoImageWarper{
    position: relative;
    height: 2em;
    width: 6em;
  }
  .bar-item{
    display: flex;
    justify-content: space-between;
    height: 7vh;
    /* padding: 0.4em; */
    border-bottom: 3px solid whitesmoke
  }
 .bar-flex i{
   font-size: 30px;
    margin-top: 0.4em; 
   margin-right: 0.4em; 
 }
 .menu-nav-link {
  height: 20rem;
  width: 100%;
}
 .mobile-menu-link {
  flex-direction: column;
  position: absolute;
  top: 3em;
  right: 0px;
  background-color: white;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-right: 11.9em; */
  /* z-index: 9999; */
}
.menu-nav-link{
  height: 20rem;
  width: 100%;
}
 .mobile-menu-link {
  flex-direction: column;
  position: absolute;
  top: 3em;
  right: 0px;
  background-color: white;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-right: 11.9em; */
  /* z-index: 9999; */
}


.bar-menu{
  /* padding: 1em; */
}
.bar-menu .menu {
 padding: 0.9em;
}
 .menu {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  background-color: transparent;
  font-family:'Open Sans', Arial, Helvetica, sans-serif ;
  font-style: normal;
  margin-left: 1em;
  /* border-bottom: 1px solid red; */
}
.line{
  padding: 1px;
  /* margin-top: 1em; */
}.heading-image {
  height: 25vh;
}


}

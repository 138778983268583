* {
  padding: 0;
  margin: 0;
}
.contact-symbol h1 {
  color: #294859;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
}
.contact-symbol h6 {
  color: #294859;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1em;
}
.form-text {
  color: #26ce69;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1em;
  cursor: pointer;
}
.input-file {
  color: #466477;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.form-term {
  color: #26ce69;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.login-file-1 {
  margin-top: 4em;
}
.form-head {
  text-align: center;

  position: relative;
  margin-top: 2em;
  font-size: 15px;
}
.form-head::before {
  background-color: grey;
  display: block;
  width: 45%;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
}
.form-head::after {
  background-color: grey;
  display: block;
  width: 45%;
  height: 1px;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
}
@media (max-width: 1100px){
  
  .input-head-2{
    text-align: center;
  }
}
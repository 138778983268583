* {
  padding: 0;
  margin: 0;
}

.info-head-1 {
  background-color: #fff;
  height: 10vh;
}
/* .logo-head {
  padding-top: 1em;
  margin-left: 3.9em;
} */

.menu-head li {
  margin-left: 2.3em;
  color: black;
  cursor: pointer;
  padding-top: 0em;
}
.menu-head li :hover {
  color: green;
}
.navbar-1 {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  background-color: #fff;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  padding: 0.4em;
}
.hamburger-menu {
  display: none;
}

.menu-head {
  display: flex;
  padding-top: 1.7em;

  margin-left: 29em;
}
.about-head-button {
  padding-right: 5em;
  padding-top: 0.9em;
}
.about-btn-head {
  padding: 0.6em;
  background-color: #2cde73;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-style: normal;
  transition: transform 0.2s;
  border: 1px solid transparent;
  width: 100px;
}
.about-btn-head:hover {
  background-color: whitesmoke;
  color: #236c7e;
  border: 1px solid #236c7e;
}
/*quick*/
.contact-main {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 5em;
  padding-right: 10em;

  align-items: center;
}
.contact-info-2 img {
  height: 40vh;
  object-fit: cover;
}
.contact-text {
  margin-left: 4em;
}
.contact-info-2 {
  margin-top: 2em;
}
form .txt-field {
  position: relative;
  border-bottom: 2px solid whitesmoke;
  margin: 30px 0;
}
.txt-field input {
  width: 120%;
  padding: 0 5px;
  height: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  border: none;
}
.txt-field label {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: grey;
  font-weight: 400;
}
.login-text {
  display: flex;
  padding-top: 1.3em;
  justify-content: space-around;
}
.contact-text h1 {
  color: #294859;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
}
.contact-text h6 {
  color: #294859;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1em;
}
.contact-sign {
  color: #26ce69;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.login-file {
  width: 50%;
}
.login-button {
  padding: 15px 25px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  background-color: #26ce69;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.login-text h5 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #26ce69;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.6em;
}
.input-head h2 {
  margin-top: 2em;
  padding: 15px 30px;
  border: 1px solid #26ce69;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  color: #26ce69;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 500;
  width: 90%;
  border-radius: 5px;
}
.input-icon {
  padding: 0.4em;
  font-size: 20px;
}
form .input-head-2 {
  font-size: 12px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #294859;
  font-weight: 400;
  margin-left: 5em;
  margin-top: 1.5em;
}
.input-head:hover h2 {
  background-color: #26ce69;
  color: #fff;
  cursor: pointer;
}
.element-section h1 {
  font-size: 25px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: noraml;
  color: #294859;
  font-weight: 400;
}
.element-section {
  margin-left: 4em;
}
.element-tag {
  display: flex;
  margin-top: 3em;
  justify-content: space-around;
}
.element-contact .txt-field {
  position: relative;
  border-bottom: 1px solid grey;
  margin: 30px 0;
}
.txt-field label {
  font-size: 15px;
}
.element-style h5 {
  font-size: 15px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #26ce69;
  margin-top: 1em;
}
.element-method {
  margin-left: 7em;
}
.element-methos {
  padding: 1em;
  width: 120%;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  background-color: #26ce69;
  color: #fff;
  border: none;
  border-radius: 5px;
}
@media (max-width: 1100px) {
  .hamburger-menu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    /* padding-right: 1em; */
    /* margin-left: 4em; */
  }
  .hamburger-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .contact-info-2 img {
    display: none;
  }
  .mobile-menu-link {
    flex-direction: column;
    position: absolute;
    top: 3em;
    right: 0px;
    background-color: white;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 11.9em;
    z-index: 9999;
  }
  .menu-nav-link {
    height: 15rem;
    width: 100%;
  }
  .mobile-menu-link .menu-head {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    z-index: 5;
    width: 117px;
  }
  /* .navbar-1 {
    width: 289%;
  } */
  .mobile-menu-link .menu li {
    transition-delay: 0.2s;
    font-size: 15px;
  }
  .menu-head li {
    width: 162px;
  }

  .about-head-button {
    display: none;
  }
  .logo-head {
    margin-left: 0.5em;
    padding-top: 1em;
  } form .txt-field {
    width: 80%;
  }
 
  .login-text h5 {
    font-size: 12px;
  }
  .input-head h2 {
    padding: 10px 10px;
  }
  form .input-head-2 {
    margin: 0px;
  }
  .contact-text {
    margin-left: 0px;
  }
  .contact-main {
    /* padding-right: 0px;
    margin-left: 1em; */
    padding: 4em;
  }
  .navbar-1{
    display: none;
  }
}

* {
  padding: 0;
  margin: 0;
}
.containor-field {
  height: 100vh;
}
.bar-item{
  display: none;
}
.menu-link-1{
  display: none;
}
.rec-slider-container{
  margin: 0px !important;
}
.member{
  width: 100%;
}
.rec-slider-containor{
  margin: 0px;
}
.image-field {
  background-image: url("../src/assets/home.jpg");

  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: contain; */
  background-position: center;
  /* height: 100vh; */
  z-index: -1;
  width: 100%;
}
.image-field-2 {
  background-image: url("../src/assets/world.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  /* height: 95vh; */
  z-index: -1;
  width: 100%;
}
/* .menu-link{
  display: block;
} */
.fPDpuU {
  margin: 0px !important;
}

.rec-arrow-left {
  position: absolute;
  z-index: 999;
}
.rec-arrow-right {
  position: absolute;
  right: 0;
}
.jRFJkP {
  z-index: 1;
  margin: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  background-color: #d8e3e5;
  height: 10vh;
}
.logo {
  padding-top: 1em;
  margin-left: 2em;
}
.logo img{
  height: 40px;
}
.menu {
  display: flex;
  padding-top: 1.5em;
  /* padding-right: 5em; */
  margin-left: 29em;
}
.menu li {
  margin-left: 2em;
  color: black;
  cursor: pointer;
  /* padding-top: 10em; */
}
.menu li :hover {
  color: green;
}
.about-button {
  padding-right: 5em;
  padding-top: 0.7em;
  /* padding: 1em; */
}
.about-btn {
  padding: 0.7em;
  background-color: #2cde73;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-style: normal;
  transition: transform 0.2s;
  border: 1px solid transparent;
  width: 100px;
  /* margin-left: 2em;
  padding-top: 1em; */
}
.about-btn:hover {
  background-color: whitesmoke;
  color: #236c7e;
  border: 1px solid #236c7e;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.main-containor {
  padding: 10em;
  margin-left: 6em;
}
.main-containor h1 {
  font-size: 45px;
  line-height: 54px;
  font-weight: 700;
  font-style: normal;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #233038;
}
.main-containor p {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;
  font-style: normal;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #233038;
}
.btn-1 {
  padding: 1em;
  margin-top: 2em;
  width: 17%;
  border: none;
  background-color: #2cde73;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}
/*hamburger*/
.hamburger-menu {
  display: none;
}
/*card-containor*/
.card-containor {
  text-align: center;
  margin-top: 3em;
}
.card-containor h1 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  color: #294859;
  font-weight: 300;
  line-height: 43px;
}
.card-containor p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  color: #466477;
  font-weight: 400;
  line-height: 27px;
  margin-top: 1em;
}
.card-center {
  display: flex;
  /* justify-content: space-around; */
  justify-content: center;
  margin-top: 4em;
  padding-right: 3em;
}
.card-info:hover {
  box-shadow: tomato;
}
.main-card {
  text-align: center;
  border: 1px solid rgb(201, 201, 201);
  padding-right: 100px;
  padding: 2em;
  height: 45vh;
  width: 270px;
  margin-left: 3em;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s;
  /* margin-top: 2em; */
}

.main-card h2 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  color: #466477;
  line-height: 19px;
  margin-top: 1em;
}
.main-card h5 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  color: #466477;
  line-height: 27px;
  margin-top: 1em;
}
.card-info .main-card:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
/*investment*/
.investment-containor {
  background-color: whitesmoke;
  padding: 3em;
  /* height: 70vh; */
}
.investment-containor h1 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  color: #294859;
  font-weight: 300;
  text-align: center;
  padding-top: 0.1em;
}
.investment-containor {
  margin-top: 3em;
  /* padding: 2em; */
}
.invest-field {
  display: flex;
  /* margin-left: 2em; */
  margin-top: 2em;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.invest-area {
  /* margin-left: 1em; */

  border-radius: 10px;
  height: 70vh;
  background-color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  /* margin-top: 5em; */
}
.invest-mark {
  display: flex;
  justify-content: space-around;
}
.invest-code img {
  height: 10px;
}
.invest-area img {
  height: 30vh;
  width: 350px;
  object-fit: cover;
}
.invest-area h2 {
  font-size: 24px;
  font-style: normal;
  color: #294859;
  font-weight: 700;
}
.invest-hold h6 {
  font-size: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #466477;
  font-weight: 400;
  font-size: 15px;
  margin-top: 1em;
}
.invest-icon i {
  font-size: 15px;
  padding: 0.5em;
}
hr {
  margin-top: 2em;
  height: 7px;
  background-color: whitesmoke;
  outline: none;
  border: none;
}
.invest-hold {
  padding: 2em;
  /* padding-bottom: 2em; */
}
.invest-look h5 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  color: #466477;
  font-weight: 600;
}
.invest-look p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-style: normal;
  color: #466477;
  font-weight: 600;
  margin-top: 0.5em;
}
.invest-hook img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  position: absolute;
  top: 10em;
  left: 16em;
  border: 4px solid #fff;
  border-radius: 50%;
}
.invest-rotet {
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  top: 2em;
  left: -3em;
  width: 7.5rem;
  text-align: center;
  transform: rotate(-45deg);
  font-weight: 700;
  color: grey;
  background-color: #fff !important;
}
/*section page */
.section-image {
  background-image: url("https://realestate-classic.crowdfundingscript.com/theme/classic/assets/images/call-to-action-bg.jpg");

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  height: 60vh;
  width: 100%;
  /* margin-top: 3em; */
}
.section-row {
  text-align: center;
  color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  padding-top: 6em;
}
.section-row h1 {
  font-size: 36px;
  font-style: normal;
  color: #ffffff;
  font-weight: 300;
  line-height: 43px;
}
.section-row p {
  font-size: 15px;
  font-style: normal;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 300;
  line-height: 27px;
  margin-top: 1.5em;
}
.section-btn {
  padding: 1em;
  margin-top: 1.5em;
  width: 10%;
  border: none;
  background-color: #2cde73;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
/*position section */
.position-info {
  text-align: center;
  margin-top: 3em;
}
.position-info h1 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  color: #294859;
  font-weight: 300;
  line-height: 43px;
}
.position-containor {
  display: flex;
  justify-content: space-around;
  padding: 4em;
}
.main-position {
  text-align: center;
  border: 1px solid whitesmoke;
  padding: 1em;
  width: 18.5%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.main-position img {
  height: 20vh;

  object-fit: cover;
}
.main-position h5 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.main-position p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #26cc69;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}
.main-icon i {
  padding: 0.5em;
  font-size: 12px;
}
/*Categories section */
.categories-info {
  text-align: center;
  margin-top: 3em;
}
.categories-info h1 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-style: normal;
  color: #294859;
  font-weight: 300;
  line-height: 43px;
}
.categories-info p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  color: #466477;
  font-weight: 400;
  line-height: 27px;
  margin-top: 1em;
}
.categories-class {
  display: flex;
  justify-content: space-around;
  padding: 2em;
  margin-top: 2em;
}
.categories-main {
  text-align: center;
  border: 2px solid whitesmoke;
  padding: 2.3em;
  width: 20%;
  height: 40vh;
  cursor: pointer;
  border-radius: 5px;
}
.categories-class .categories-main:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}
.categories-main h1 {
  font-size: 16px;
  font-style: normal;
  color: #466477;
  font-weight: 600;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin-top: 1em;
}
.categories-main p {
  font-size: 15px;
  font-style: normal;
  color: #466477;
  font-weight: 400;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 27px;
  margin-top: 1em;
}
/*contact*/

.contact button {
  width: 200px;
  height: 50px;
  border: 0;
  background: #2cde73;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.contact-info {
  border-top: 1px solid #ddd;
  background-color: #f6f8f9;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  padding: 5em;
  /* justify-content: space-around; */
  margin-top: 3em;
}
.contact-input {
  width: 500px;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  border-radius: 5px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  margin-left: 10em;
}
.contact-input input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 2em;
  color: #242425;
}
.contact-sub h1 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #466477;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}
.contact-sub p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #466477;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
/*footer*/
.footer-section {
  display: flex;
  justify-content: space-around;
  margin-top: 6em;
  padding-right: 2em;
}
.footer-area h2 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  color: #466477;
  font-weight: 600;
  line-height: 22px;
  /* text-align: center; */
}
.footer-area ul li {
  margin-top: 1em;
  /* margin-left: 5em; */
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 15px;
  cursor: pointer;
}
.footer-area ul li:hover {
  color: #2cde73;
}
.footer-area .footer-field {
  margin-top: 2.5em;
}

.footer-area .footer-target li {
  margin-left: 0em;
}
.social-site i {
  border: 1px solid #a2a2a2;
  width: 50px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  margin-right: 0.5em;
  font-size: 25px;
  color: #a2a2a2;
  margin-bottom: 2em;
  cursor: pointer;
  margin-top: 0.5em;
}
.social-site i:hover {
  color: #26cc69;
}
.footer-area .footer-title {
  text-align: left;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  color: #466477;
  font-weight: 600;
  line-height: 22px;
  /* text-align: center; */
  margin-right: 2em;
}

.footer-text input {
  padding: 1em;
  font-size: 15px;
  font-style: normal;
  width: 30%;
  margin-top: 1em;

  outline: none;
}
.footer-line {
  /* width: 100%; */
  border: 2px solid #f6f8f9;
  height: 0px;
}
.footer-bottom {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  color: #466477;
  font-weight: 400;
  padding: 2em;
  margin-left: 3em;
}
.footer-code {
  position: relative;
  top: 100%;
  left: -40px;
  font-size: 14px;
}
.footer-bottom{
  display: flex;
  justify-content: space-between;
}
.footer-start h6{
  font-size: 15px;
  font-style: normal;
  color: #466477;
  font-weight: 400;
  font-family:  Roboto, Arial, Helvetica, sans-serif;
}
.footer-link-brand a{
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color:#466477 ;

}
/*media query*/
@media (max-width: 1100px) {
  .hamburger-menu {
    display: flex;
    /* justify-content: flex-end; */
    width:100%;
    /* margin-left: 4em; */
    padding: 0.4em;
  }
  .hamburger-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
   
  }
  .navbar {
    width: 100%;
  }

  /* .mobile-menu-link {
    flex-direction: column;
    position: absolute;
    top: 3em;
    right: 0px;
    background-color: white;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 11.9em;
    z-index: 9999;
    width: 181%;
  } */
  /* .menu-nav-link {
    height: 15rem;
    width: 100%;
  } */
  /* .mobile-menu-link .menu {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    z-index: 5;
    padding-right: 11em;
  } */
  /* .mobile-menu-link .menu li {
    transition-delay: 0.2s;
    font-size: 15px;
  } */
  .card-center {
    flex-direction: column;
  }

  .main-card {
    margin: 1em;
    padding: 1em;
    margin-left: 1.4em;
    width: 90%;
    height: 17em;
  }
  /* .menu {
    display: none;
  } */
  .image-field {
    height: 55vh;
  }
  .image-field-2 {
    height: 55vh;
  }
  .btn-1 {
    width: 50%;
  }
  .about-button {
    display: none;
  }
  .main-containor {
    padding: 1em;
    margin-left: 1em;
    margin-top: 5em;
    /* text-align: center; */

  }
  .main-containor h1 {
    font-size: 20px;
    line-height: 1.3em;
  }
  .invest-field {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0em;
  }

  .logo {
    margin-left: 1em;
    padding-top: 1em;
  }
  .logo img{
    height: 25px;
  }
  .hamburger-menu{
    margin-left: 6.7em;
  }
  
  .card-containor h1 {
    font-size: 30px;
  }
  .card-containor {
    padding: 1em;
  }
  .investment-containor h1 {
    font-size: 25px;
  }
  /* .section-image {
    height: 120vh;
  } */
  .section-row h1 {
    font-size: 35px;
  }
  .section-btn {
    width: 50%;
    margin-top: 1em;
  }
  .position-containor {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-position {
    width: 110%;
    margin: 1em;
  }
  .categories-info h1 {
    font-size: 25px;
  }
  .categories-class {
    flex-direction: column;
  }
  .categories-main {
    width: 68%;
    margin: 1em;
    height: 28vh;
  }
  .contact-info {
    flex-direction: column;
    padding: 2em;
  }
  .contact-input {
    margin-left: 0px;
    width: 100%;
  }
  .contact button {
    width: 80%;
    font-size: 10px;
  }
  .footer-section {
    flex-direction: column;
    padding: 1em;
    /* margin-top: 6em; */
  }
  .footer-area .footer-target li {
    margin-left: 1em;
  }
  .footer-area h2 {
    margin-left: 1em;
    margin-top: 1em;
  }
  .footer-area .footer-title {
    margin-left: 1.2em;
    margin-top: 1em;
  }
  .social-site i {
    width: 30px;
    margin-left: 0.5em;
  }
  .footer-bottom {
    margin-left: 1em;
    padding: 1.3em;
  }
  .invest-area img {
    /* width: 260px; */
    height: 21vh;
  }
  .invest-area {
    margin: 1em;
    height: 50vh;
  }
  .navbar{
    height: 7vh;
  }
  /* .menu-nav-link{
    display: none;
  } */
  .invest-hold {
    padding: 1em;
    padding-bottom: 1em;
  }
  .footer-text input {
    margin-left: 1em;
    margin-top: 0.4em;
  }
  /* .nav-info {
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
  } */
  .navbar {
    background-color: #ffff;
  }
  .footer-area ul li {
    margin-left: 1em;
  }
  .footer-bottom{
    flex-direction: column;
    text-align: center;
  }
  .invest-hook img {
    display: none;
  }
  .nav-info{
    display: none;
  }
  .bar-item{
    display: block;
  }
  .bar-image img{
      height: 40px;
      width: 100%;
      margin-top: 0.5em;
      position: relative;
      margin-left: 1em;
  }
  .logoImageWarper{
    position: relative;
    height: 2em;
    width: 6em;
  }
  .bar-item{
    display: flex;
    justify-content: space-between;
    height: 8vh;
    /* padding: 0.4em; */
    border-bottom: 3px solid whitesmoke
  }
 .bar-flex i{
   font-size: 30px;
   margin-top: 0.2em; 
    margin-right: 1em; 
 }
 .menu-nav-link {
  height: 20rem;
  width: 100%;
}
 .mobile-menu-link {
  flex-direction: column;
  position: absolute;
  top: 3em;
  right: 0px;
  background-color: white;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-right: 11.9em; */
  /* z-index: 9999; */
}
.menu-nav-link{
  height: 20rem;
  width: 100%;
}
 .mobile-menu-link {
  flex-direction: column;
  position: absolute;
  top: 3em;
  right: 0px;
  background-color: white;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-right: 11.9em; */
  /* z-index: 9999; */
}


.bar-menu{
  /* padding: 1em; */
}
.bar-menu .menu {
 padding: 0.9em;
}
 .menu {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  background-color: transparent;
  font-family:'Open Sans', Arial, Helvetica, sans-serif ;
  font-style: normal;
  margin-left: 1em;
  /* border-bottom: 1px solid red; */
}
.line{
  padding: 1px;
  /* margin-top: 1em; */
}
}